const BaseUrls = {
  accountManagement: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/account-management-api/api`,
  schedule: `${process.env.REACT_APP_SCHEDULE_API_URL}/api`,
  patients: `${process.env.REACT_APP_PATIENTS_URL}/api`,
  appointments: `${process.env.REACT_APP_APPOINTMENTS_URL}/api`,
  lookUps: `${process.env.REACT_APP_LOOK_UPS_URL}/api`,
  static: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/static-api/api`,
  payment: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/payment-api/api`,
  vezeetaNative: `${process.env.REACT_APP_VEZEETA_NATIVE_URL}/api`,
  salesForce: `${process.env.REACT_APP_SALES_FORCE_URL}/api`,
  profile: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/profile-api/graphql`,
  offers: `${process.env.REACT_APP_SERVICES_URL}/api`,
  offersGateway: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/so-api/api`,
  aman: `${process.env.REACT_APP_AMAN_URL}/api`,
  prescription: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/prescription-api/api`,
  homeVisits: `${process.env.REACT_APP_HOMEVISITS_URL}/api`,
  crmGateWay: `${process.env.REACT_APP_CRM_GATEWAY}/api`,
  accountManagementAdsGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/ads-api/api`,
  accountManagementSOGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/so-api/api`,
  accountManagementPlansGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/plans-api/api`,
  accountManagementInvoicesGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/billing-api/api`,
  accountManagementPaymentGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/payment-api/api`,
  accountManagementPatientsGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/patients-ms-api/api`,
  accountManagementAPIsGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/account-management-api/api`,
  accountManagementReservationGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/reservation-ms-api/api`,
  accountManagementScheduleMSGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/schedule-ms-api/api`,
  accountManagementEPharmaMSGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/epharmacy-invenotry-api/api`,
  accountManagementVDoctorCoreGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/v-doc-core-api/api`,
  accountManagementLabsGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/labscatalog-api/api`,
  vezeetaScheduleGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/vezeeta-schedule/api`,
  accountManagementScansGateway: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/scanscatalog-api/api`,
  accountManagementmedicalServicesGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/medicalservices-api/api`,
  medicalServicesGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/medicalservices-api/api`,
  contentUploader: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/contentuploader/api`,
  appointmentsV2: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/reservation-ms-api/api`,
  accountManagementmedicalCardsGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/medicalcards-api/api`,
  accountManagementDiscountsGateWay: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/discounts-api/api`,
  telehealthAPI: `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_GW_URL}/telehealth-api/api`
};

export const Urls = {
  getTeleHealthVideoCallAssets: `${BaseUrls.telehealthAPI}/v1/appointments`,
  getVezeetaSchedule: `${BaseUrls.vezeetaScheduleGateWay}/Schedule`,
  getCalendarView: `${BaseUrls.accountManagementScheduleMSGateWay}/branch`,
  getDoctorWorkingHoursShiftsAndRooms: `${BaseUrls.accountManagementScheduleMSGateWay}/WorkingHours/GetWorkingHours`,
  assignShiftToRoom: `${BaseUrls.accountManagementScheduleMSGateWay}/physicalroomshift/assignshifts`,
  getBranchWorkingHours: `${BaseUrls.accountManagementScheduleMSGateWay}/branches`,
  setBranchWorkingHours: `${BaseUrls.accountManagementScheduleMSGateWay}/branches`,
  addRoom: `${BaseUrls.accountManagementAPIsGateWay}/branch`,
  editRoom: `${BaseUrls.accountManagementAPIsGateWay}/physicalroom`,
  deleteRoom: `${BaseUrls.accountManagementAPIsGateWay}/physicalroom`,
  getRooms: `${BaseUrls.accountManagementAPIsGateWay}/branch`,
  addVitals: `${BaseUrls.accountManagementReservationGateWay}/reservation`,
  updateVitals: `${BaseUrls.accountManagementReservationGateWay}/vital`,
  getEntity: `${BaseUrls.accountManagementSOGateWay}/entity-services`,
  getEntityVDoc: `${BaseUrls.accountManagementVDoctorCoreGateWay}/entities`,
  getServices: `${BaseUrls.accountManagementSOGateWay}/services`,
  getBranchDetails: `${BaseUrls.accountManagementVDoctorCoreGateWay}/branches`,
  getDoctorDetails: `${BaseUrls.accountManagementVDoctorCoreGateWay}/doctors`,
  getPreivouslyAddedItems: `${BaseUrls.prescription}/Eprescription/GetPreivouslyAddedItems`,
  getEPrescription: `${BaseUrls.prescription}/Eprescription/GetEPrescription`,
  upsertEPrescription: `${BaseUrls.prescription}/Eprescription/UpsertEPrescription`,
  getPrescriptionSettings: `${BaseUrls.prescription}/Eprescription/GetPrescriptionSettings`,
  pharmaProductShape: `${BaseUrls.accountManagementEPharmaMSGateWay}/V2/ProductShapes`,
  getLabTests: `${BaseUrls.accountManagementLabsGateWay}/services`,
  getScans: `${BaseUrls.accountManagementScansGateway}/scan`,
  reservationSearch: `${BaseUrls.accountManagementReservationGateWay}/v2/reservations`,
  countReservationsInRange2: `${BaseUrls.accountManagementReservationGateWay}/Reservation/CountAllReservationsInRange2`,
  editAssistant: `${BaseUrls.accountManagementAPIsGateWay}/v2/Account/EditBasicInfo`,
  updateLoginStatus: `${BaseUrls.accountManagementAPIsGateWay}/Account/UpdateLoginStatus`,
  registerAccount: `${BaseUrls.accountManagementAPIsGateWay}/Account/RegisterAccount`,
  searchAccounts: `${BaseUrls.accountManagementAPIsGateWay}/Account/SearchAccounts`,
  editPatientDetails: `${BaseUrls.accountManagementPatientsGateWay}/patients/editPatient`,
  getPatientList: `${BaseUrls.accountManagementPatientsGateWay}/v2/patients/searchPatients`,
  getPatientInfo: `${BaseUrls.accountManagementPatientsGateWay}/v2/patients`,
  getAppointmentInvoice: `${BaseUrls.accountManagementInvoicesGateWay}/invoices/operationKey:`,
  getCultures: `${BaseUrls.lookUps}/Cultures`,
  getCities: `${BaseUrls.static}/City/GetCities`,
  getAreas: `${BaseUrls.static}/Area/GetAreas`,
  getCityAreaHv: `${BaseUrls.static}/Area/GetCitiesAreasByBookingType`,
  getbookingtypes: `${BaseUrls.static}/bookingtype/getbookingtypes`,
  // The _unsafe_ Urls below is old, and should not be used, instead use the safe urls above
  // This old Urls is kept here as a workaround to add a fix to accounts-web
  // once accounts-web is updated to use static base url, it should use "getCities", & "getAreas"
  // and unsfae urls should be removed
  getSpecialities: `${BaseUrls.lookUps}/Specialities`,
  GetPrefixTitlesByCategoryTypeKey: `${BaseUrls.static}/PrefixTitle/GetPrefixTitlesByCategoryTypeKey?`,
  getPrefixTitles: `${BaseUrls.static}/PrefixTitle/GetPrefixTitlesByCountryId?`,
  currencyConversion: `${BaseUrls.static}/Currency/GetCurrencyConversions`,
  getInsuranceProvidersByCountryId: `${BaseUrls.static}/Insurance/GetInsuranceProvidersByCountryId?countryId=`,
  getProfessionalTitles: `${BaseUrls.static}/DoctorTitle/GetDoctorTitlesByCountryId?`,

  getServicesAndChildServices: `${BaseUrls.accountManagementSOGateWay}/home/GetServicesAndChildServices`,
  getInsuranceServicesAndChildServices: `${BaseUrls.accountManagementSOGateWay}/service/GetInsuranceServicesAndChildServices`,
  getBillingInvoices: `${BaseUrls.accountManagementInvoicesGateWay}/invoices/query`,

  getPatientAppointmentsUrl: `${BaseUrls.schedule}api/Schedule/GetPatientReservations`,
  getReservationDetailsUrl: `${BaseUrls.schedule}api/Schedule/GetReservationDetails`,
  getConfirmationsUrl: `${BaseUrls.schedule}/Confirmations/GetWeeksConfirmations`,
  saveConfirmationsUrl: `${BaseUrls.schedule}/Confirmations/SetConfirmations`,
  getWorkingHours: `${BaseUrls.schedule}/WorkingHours/GetWorkingHours`,
  saveWorkingHours: `${BaseUrls.schedule}/WorkingHours/SaveWorkingHours`,
  getScheduleTypes: `${BaseUrls.schedule}/Schedule/getScheduleTypes`,
  setScheduleTypes: `${BaseUrls.schedule}/Schedule/setScheduleTypes`,
  getAllowReservationWindow: `${BaseUrls.schedule}/Schedule/getAllowReservationWindow`,
  setAllowReservationWindow: `${BaseUrls.schedule}/Schedule/setAllowReservationWindow`,
  getVacations: `${BaseUrls.schedule}/Vacation/GetVacations`,
  setVacations: `${BaseUrls.accountManagementScheduleMSGateWay}/v2/Vacation/SetVacations`,
  getRamadanSchedule: `${BaseUrls.schedule}/Schedule/GetRamdanSchedule`,

  getPatientRelativeTypes: `${BaseUrls.patients}/Patients/GetPatientRelativeTypes`,
  getPatientByKeyUrl: `${BaseUrls.patients}/Patients/GetPatientsByPatientKeys`,
  searchPatientsByKeyword: `${BaseUrls.patients}/Patients/GetPatientsList`,
  createPatient: `${BaseUrls.patients}/Patients/AddPatients`,
  editPatient: `${BaseUrls.patients}/Patients/EditPatient`,
  deletePatientByKeyUrl: `${BaseUrls.patients}/Patients/DeletePatients`,
  patientMedicalCards: `${BaseUrls.accountManagementmedicalCardsGateWay}/medicalcards`,

  fetchAppointmentsUrl: `${BaseUrls.appointments}/Reservation/GetReservationDetails`,
  fetchAppointmentsUrl2: `${BaseUrls.appointmentsV2}/v2/reservations`,
  createAppointmentUrl: `${BaseUrls.appointments}/Reservation/UpsertReservations`,
  editAppointmentUrl: `${BaseUrls.appointments}/Reservation/UpsertReservationsV2`,
  markAppointmentAsNoShow: `${BaseUrls.appointments}/Reservation/MarkReservationsAsNoShow`,
  undoAppointmentNoShow: `${BaseUrls.appointments}/Reservation/UndoNoShowReservations`,
  checkInAppointment: `${BaseUrls.appointments}/Reservation/UpdateReservationsStatus`,
  cancelAppointment: `${BaseUrls.appointmentsV2}/reservations`,
  countReservationsInRange: `${BaseUrls.appointments}/Reservation/CountReservationsInRange`,
  updateReservationsInsurance: `${BaseUrls.appointments}/Reservation/UpdateReservationsInsurance`,
  submitResults: `${BaseUrls.appointmentsV2}/reservations`,
  uploadContent: `${BaseUrls.contentUploader}/contentuploader`,

  getAllSpecialities: `${BaseUrls.vezeetaNative}/Specialities/GetMainSpecialities`,
  getMainSpecialities: `${BaseUrls.vezeetaNative}/Specialities/GetMainSpecialities`,
  getSubSpecialities: `${BaseUrls.vezeetaNative}/Specialities/GetSubSpecialities`,
  getDoctorSpecialty: `${BaseUrls.vezeetaNative}/DoctorSpecialities/GetMainSpecialtyByAccountKey`,

  validateToken: `${BaseUrls.accountManagement}/Account/ValidateToken`,
  reGenerateToken: `${BaseUrls.accountManagement}/Account/ReGenerateToken`,
  getAccountByKey: `${BaseUrls.accountManagement}/Account/GetAccountByKey`,
  getAccountsByKeys: `${BaseUrls.accountManagement}/Account/GetAccountsByKeys`,
  getAccountStructure: `${BaseUrls.accountManagementAPIsGateWay}/Account/GetAccountStructure`,
  signOut: `${BaseUrls.accountManagement}/Account/SignOut`,

  plans: {
    getPlans: `${BaseUrls.accountManagementPlansGateWay}/Plans/Entities`,
    getPlansSingleDr: `${BaseUrls.accountManagementPlansGateWay}/Plans/Doctors`,
    subscribePlan: `${BaseUrls.accountManagementPlansGateWay}/v2/Plans`,
  },

  sso: {
    validateToken: `${BaseUrls.accountManagement}/Account/ValidateToken`,
  },

  account: {
    getAccountByKey: `${BaseUrls.accountManagement}/Account/GetAccountByKey?accountKey=`,
    registerAccount: `${BaseUrls.accountManagementAPIsGateWay}/Account/RegisterAccount`,
    saveProfilePhoto: `${BaseUrls.accountManagement}/Account/SaveProfilePhoto`,
    signIn: `${BaseUrls.accountManagement}/Account/SignIn`,
    socialSignIn: `${BaseUrls.accountManagement}/Account/SocialSignIn`,
    editAccount: `${BaseUrls.accountManagementAPIsGateWay}/v2/Account/EditBasicInfo`,
    getAccount: `${BaseUrls.accountManagement}/Account/GetBasicInfo`,
    sendForgetPasswordMessage: `${BaseUrls.accountManagement}/Messaging/SendForgetPasswordMessage`,
    updatePassword: `${BaseUrls.accountManagement}/Account/UpdatePassword`,
    setUserDefaultLangauge: `${BaseUrls.accountManagement}/Account/SetUserDefaultLanguage`,
  },

  services: {
    getServices: `${BaseUrls.medicalServicesGateWay}/services`,
    getCategories: `${BaseUrls.accountManagementmedicalServicesGateWay}/categories`,
    getServicesPrice: `${BaseUrls.accountManagementmedicalServicesGateWay}/serviceprices`,
    addServicePrice: `${BaseUrls.accountManagementmedicalServicesGateWay}/serviceprices`,
    addBulkServicesPrice: `${BaseUrls.accountManagementmedicalServicesGateWay}/serviceprices/bulk`,
    calculateDiscounts: `${BaseUrls.accountManagementDiscountsGateWay}/discounts/calculate`,
  },

  offers: {
    getHealthGroups: `${BaseUrls.accountManagementSOGateWay}/home/GetHealthGroupsAndRelatedServices`,
    getChildrenServices: `${BaseUrls.accountManagementSOGateWay}/home/GetServiceChilds`,
    getUnitsPerCountry: `${BaseUrls.accountManagementSOGateWay}/Attribute/GetUnitsPerCountry`,
    getOffers: `${BaseUrls.accountManagementSOGateWay}/provider/GetProviderOffers`,
    addOffer: `${BaseUrls.offersGateway}/provider/addservice`,
    editOffer: `${BaseUrls.offersGateway}/provider/editservice`,
    redeemCode: `${BaseUrls.accountManagementSOGateWay}/provider/RedeemCode`,
  },

  entity: {
    getCategoriesAndTypes: `${BaseUrls.accountManagement}/Entity/GetEntitiesCategoriesAndTypes`,
    addEntityImage: `${BaseUrls.accountManagement}/Entity/AddEntityImage`,
    createEntity: `${BaseUrls.accountManagement}/Entity/UpsertEntity`,
    getEntity: `${BaseUrls.accountManagement}/Entity/GetClinic`,
    GetBranchInsurances: `${BaseUrls.accountManagement}/Entity/GetBranchInsurances`,
    UpsertBranchInsurances: `${BaseUrls.accountManagement}/Entity/UpsertBranchInsurances`,
    editAddress: `${BaseUrls.accountManagement}/entityAddresses`,
  },

  aman: {
    providers: `${BaseUrls.aman}/providers`,
    signUp: `${BaseUrls.aman}/providers/signup`,
  },

  assistant: {
    getAssistants: `${BaseUrls.accountManagement}/Account/GetAssistants?entityKey=`,
    addAssistant: `${BaseUrls.accountManagement}/Account/CreateAssistant`,
    toggleAssistantState: `${BaseUrls.accountManagement}/Account/ToggleAssistantState`,
  },

  payment: {
    getSalesForceInvoice: `${BaseUrls.crmGateWay}/salesforce/invoices`,
    getPaymentTypes: `${BaseUrls.payment}/PaymentMethod/GetPaymentTypesAndMethods?`,
    getPaymentCycles: `${BaseUrls.accountManagement}/Payment/GetPaymentCycles?productLineId=`,
    getAccountPaymentDetails: `${BaseUrls.payment}/Account/GetAccountPaymentDetails?accountkey=`,
    checkIfAccountHasCards: `${BaseUrls.payment}/Account/CheckIfAccountHasCards`,
    setPaymentDetails: `${BaseUrls.payment}/Account/SetAccountPaymentTypeAndMethod`,
    getCountryPaymentAttributes: `${BaseUrls.accountManagement}/Payment/GetCountryPaymentAttributes`,
    addAccountCardAndGetMerchantPageInfo: `${BaseUrls.payment}/Payfort/AddAccountCardAndGetMerchantPageInfo`,
    updateAndChargeAccountCard: `${BaseUrls.payment}/Payfort/UpdateAndChargeAccountCard?`,
    submitAccountChargeResponse: `${BaseUrls.payment}/Payfort/SubmitChargeAccountCardResponse?`,
    addTransaction: `${BaseUrls.payment}/Transaction/AddTransaction`,
    setPaymentMethod: `${BaseUrls.accountManagement}/Payment/SetPaymentMethod`,
    getTransactionByTransactionKey: `${BaseUrls.accountManagementPaymentGateWay}/Transaction/GetValidTransactionByTransactionKey?transactionKey=`,
  },

  verification: {
    verifyPhone: `${BaseUrls.accountManagement}/Verification/VerifyPhone`,
    sendPhoneVerificatoinCode: `${BaseUrls.accountManagement}/Verification/SendPhoneVerificatoinCode`,
  },

  productLines: {
    getProductLines: `${BaseUrls.accountManagement}/ProductLine/GetProductLines`,
    getEditableProductLines: `${BaseUrls.accountManagement}/ProductLine/GetClinicDetails`,
    upsertBranches: `${BaseUrls.accountManagement}/ProductLine/UpsertBranches`,
    getRegisteredProductLines: `${BaseUrls.accountManagement}/ProductLine/GetRegisteredProductLines`,
    customizeBook: `${BaseUrls.accountManagement}/ProductLine/CustomizeBook`,
    customizeCare: `${BaseUrls.accountManagement}/ProductLine/CustomizeCare`,
    getSpecialityGroups: `${BaseUrls.accountManagement} /Speciality/GetSpecialityGroups`,
    getCustomizeCare: `${BaseUrls.accountManagement}/ProductLine/GetCustomizeCare`,
    getCustomizeBook: `${BaseUrls.accountManagement}/ProductLine/GetCustomizeBook`,
    deleteBook: `${BaseUrls.accountManagement}/ProductLine/DeleteBook`,
    getCartDetails: `${BaseUrls.accountManagement}/ProductLine/GetCartDetails`,
    addFinalProductLines: `${BaseUrls.accountManagement}/ProductLine/AddFinalProductLines`,
    getProductPricings: `${BaseUrls.accountManagement}/ProductLine/GetPriceTypes?productLineId=`,
  },

  country: {
    detectCountry: `${BaseUrls.accountManagement}/Country/DetectCountry?ipAddress=`,
    getCountryCodes: `${BaseUrls.static}/Country/GetCountryCodes`,
    getAllCountries: `${BaseUrls.static}/country/GetAllCountries`,
    getCountries: `${BaseUrls.static}/Country/GetCountries`,
  },

  speciality: {
    getSpecialityGroups: `${BaseUrls.accountManagement}/Speciality/GetSpecialityGroups`,
  },

  security: {
    getIsValidPasswordToken: `${BaseUrls.accountManagement}/Security/IsValidPasswordToken?token=`,
  },

  updatePaymentMethod: `${BaseUrls.salesForce}/Accounts/UpdatePaymentMethod`,

  profile: `${BaseUrls.profile}`,

  linkUsers: `${BaseUrls.accountManagement}/Invitation/LinkUsers`,

  prescription: {
    addPrescription: `${BaseUrls.prescription}/prescription/upsertprescription`,
    getPrescription: `${BaseUrls.prescription}/prescription/getprescription?operationKey=`,
  },

  homeVisits: {
    getPatientLocationDetails: `${BaseUrls.homeVisits}/request/details?operationkey=`,
    getDoctorStats: `${BaseUrls.homeVisits}/Supplier/statsbydate`,
    getDoctorsLiveStatus: `${BaseUrls.homeVisits}/Supplier/suppliersactivity`,
  },

  sponseredAds: {
    getCampaigns: `${BaseUrls.accountManagementAdsGateWay}/campaigns/upcoming/bulk-queries`,
    placeBid: `${BaseUrls.accountManagementAdsGateWay}/bids/place`,
  },
};
